import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import pic22 from '../images/pic22.webp'; // Ensure the path to the image is correct

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is small

  return (
    <Grid
      container
      spacing={4}
      style={{
        maxWidth: '1200px',
        margin: '0 auto', // Ensures the entire grid is centered
        padding: isMobile ? '20px' : '40px', // Adjust padding for mobile
        justifyContent: 'center', // Ensures horizontal centering of the grid
        alignItems: 'center', // Ensures vertical centering of the grid
        boxSizing: 'border-box', // Prevents overflow
      }}
    >
      {/* Left Side - Text */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'flex-start' : 'flex-start', // Align text to the left on mobile
          textAlign: isMobile ? 'left' : 'left', // Ensure left-aligned text on mobile
          paddingBottom: isMobile ? '20px' : '0', // Optional, adds space between text and image for mobile
          paddingLeft: isMobile ? '20px' : '0', // Slightly shift text to the left on mobile
          marginLeft: isMobile ? '-20px' : '0', // Shift text block left on mobile
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'} // Smaller font size for mobile
          component="h1"
          style={{
            color: 'purple',
            marginBottom: '16px',
            fontWeight:'bold'
          }}
        >
          WHO ARE WE
        </Typography>
        <Typography
          variant={isMobile ? 'body1' : 'h6'} // Adjust subheading size
          component="h2"
          style={{
            fontWeight: 'bold',
            marginBottom: '24px',
            color: 'black',
          }}
        >
          Knowledge will bring you the opportunity to make a difference.
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: 'black',
            lineHeight: 1.8,
            fontSize: isMobile ? '0.9rem' : '1rem', // Smaller font for mobile
          }}
        >
          Envision Institute of Learning, under the governance of Envision Charitable Trust, headquartered in Bengaluru, primarily focuses on delivering quality education that equips students for life and career. We offer Undergraduate, Postgraduate, and Research Programmes in various disciplines. Focused teaching facilities, coupled with practical and forward-thinking programmes, lend a hand to students throughout their academic period. 
        </Typography>
      </Grid>

      {/* Right Side - Image */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          justifyContent: 'center', // Center image horizontally
          alignItems: 'center', // Center image vertically
          marginTop: isMobile ? '20px' : '0', // Add spacing for mobile
          marginLeft: isMobile ? '-60px' : '0', // Shift image to the left more on mobile
        }}
      >
        <img
          src={pic22}
          alt="Envision Institute of Learning Logo"
          style={{
            width: '100%', // Full width of its container
            maxWidth: isMobile ? '90%' : '600px', // Max width for larger screens, full width on mobile
            height: 'auto',
            borderRadius: '8px',
            boxSizing: 'border-box', // Prevents any overflow
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AboutUs;
