import React, { useState } from "react";
import { Grid, Typography, Card, CardContent, Box } from "@mui/material";

const data = [
  {
    title: "Engineering Diploma College",
    content: `
    Duration: 3 Years
    Affiliation: Viseswaraih Technology University
    Description: Engineering Diploma program offers a comprehensive foundation in engineering principles, practical skills, and problem-solving methodologies. Designed to equip students with technical expertise, the curriculum includes core subjects such as applied physics, mathematics, and specialized topics based on the chosen discipline (e.g., Mechanical, Civil, Electrical, or Computer Engineering).
  `,
  },
  {
    title: "Pharmacy Diploma College (D.Pharm)",
    content: `
      Duration: 2 Years
      Affiliation: Mysore University
      Description: Diploma in Pharmacy (D.Pharm) program is designed to provide foundational knowledge and practical skills in pharmaceutical sciences. This course prepares students for roles in the pharmaceutical industry, hospitals, and retail pharmacies. The curriculum covers essential topics such as pharmacology, pharmaceutics, pharmaceutical chemistry, and drug store management.
    `,
  },
  {
    title: "Paramedical Diploma College",
    content: `
      Duration: 2-3 Years (depending on specialization)
      Affiliation: Mysore University
      Description: Paramedical Diploma program equips students with practical knowledge and clinical skills required for various allied healthcare professions. The curriculum is designed to train professionals who assist doctors and other medical staff in delivering quality patient care. Specializations include Medical Lab Technology, Radiology, Physiotherapy, Operation Theater Technology, and more.
    `,
  },
  {
    title: "Design and Arts Diploma College",
    content: `
      Duration: 2-3 Years (depending on specialization)
      Affiliation: Mysore University
      Description: The Diploma in Design and Arts program nurtures creative talent by offering foundational and specialized knowledge in various art and design disciplines. This program combines theoretical studies with practical projects, focusing on areas such as graphic design, fashion design, interior design, animation, fine arts, and visual communication.
    `,
  },
];

export default function ProgramGrid() {
  const [selectedContent, setSelectedContent] = useState("");

  const handleCardClick = (content) => {
    setSelectedContent(content);
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Course Overview Heading */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          color: "black", // Set the text color to black
          marginBottom: 2,
        }}
      >
        <span style={{ textDecoration: "underline", textDecorationColor: "purple", fontWeight:'bold', textDecorationThickness: "2px" }}>
          Course Overview
        </span>
      </Typography>

      {/* Grid for Cards */}
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid
            item
            xs={12} // Full width on extra-small screens
            sm={6}  // Half width on small screens
            md={3}  // Quarter width on medium and larger screens
            key={index}
          >
            <Card
              onClick={() => handleCardClick(item.content)}
              sx={{
                cursor: "pointer",
                backgroundColor: "#f3e5f5", // Light purple background
                "&:hover": { boxShadow: 3 },
                height: "100%",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Display Selected Content */}
      <Box
        sx={{
          marginTop: 4,
          padding: 2,
          minHeight: "150px", // Reserve space for content
          backgroundColor: "#ede7f6", // Light purple background
          borderRadius: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center", // Center the text
          flexDirection: { xs: "column", md: "row" }, // Stacks content on mobile
        }}
      >
        {selectedContent ? (
          <Typography
            variant="body1"
            sx={{ whiteSpace: "pre-line", textAlign: "center" }}
          >
            {selectedContent}
          </Typography>
        ) : (
          <Typography variant="body1" align="center">
            Click on a card to view its details.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
