import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, styled } from '@mui/material';
import wat from '../images/wat.jpg';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleApply = () => {
    alert(`Form Applied with data: \nName: ${formData.name}, \nPhone: ${formData.phone}, \nEmail: ${formData.email}`);
  };

  const handleCancel = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
    });
  };

  const Background = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${wat})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: -1,
    animation: 'zoomInOut 10s infinite',
    '@keyframes zoomInOut': {
      '0%, 100%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.1)',
      },
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh', // Adjusted to reduce overall height
        position: 'relative',
        padding: 2,
        overflow: 'hidden',
      }}
    >
      <Background />

      <Box
        sx={{
          color: 'white',
          textAlign: 'center',
          zIndex: 1,
          marginBottom: { xs: 2, sm: 3 }, // Adjusted for mobile spacing
        }}
      >
        <Typography
          variant="h5" // Smaller heading for mobile
          sx={{
            fontWeight: 'bold',
            color: 'black',
            textDecoration: 'underline',
            textDecorationColor: 'purple',
            marginBottom: 1, // Reduced margin
          }}
        >
          Admissions Open for 2024
        </Typography>
        <Typography variant="body1">
          Embark on your educational journey with us.
        </Typography>
      </Box>

      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 2, // Reduced padding for compactness
          borderRadius: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          width: '90%', // Adjusted for mobile
          maxWidth: 320,
          zIndex: 1,
        }}
      >
        <Typography
          variant="h6" // Smaller heading
          sx={{
            fontWeight: 'bold',
            marginBottom: 2,
            textAlign: 'center',
            textDecoration: 'underline',
            textDecorationColor: 'purple',
          }}
        >
          Register Now
        </Typography>
        <Box component="form">
          <TextField
            fullWidth
            size="small"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            margin="normal"
          />
          <TextField
            fullWidth
            size="small"
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
            margin="normal"
          />
          <TextField
            fullWidth
            size="small"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, marginTop: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              fullWidth
              sx={{ color: 'purple', borderColor: 'purple' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleApply}
              fullWidth
              sx={{
                backgroundColor: 'purple',
                '&:hover': { backgroundColor: 'darkviolet' },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default App;
