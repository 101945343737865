import React from 'react';
import { Grid, Typography, Container } from '@mui/material';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#333', color: 'white', padding: '20px 0' }}>
      <Container>
        <Grid container spacing={4}>
        </Grid>
        {/* Copyright Notice */}
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
          <Typography variant="body2" align="center">
            Copyright © 2024 Diploma Colleges. All rights reserved.
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;