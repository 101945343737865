import React from 'react'
import Page1 from '../components/Page1'
// import Page2 from '../components/Page2'
import Page3 from '../components/Page3'
import Page4 from '../components/Page4'
import Page5 from '../components/Page5'
import Page6 from '../components/Page6'
import Footer from '../components/Footer'
import Page7 from '../components/Page7'
import Page8 from '../components/Page8'

import Steps from '../components/Steps'
const Home = () => {
  return (
    <div style={{overflow:'hidden'}} >
        <Page1/>
        <Steps/>
        {/* <Page2/> */}
        <Page3/>
        <Page5/>
     
        <Page4/>
        <Page8/>
        <Page7/>
   
        <Page6/>
        {/* <Steps/> */}
        <Footer/>
    </div>
  )
}

export default Home