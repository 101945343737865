import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

const VisionMission = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: 'auto', // Remove fixed height to make it responsive
        padding: { xs: '20px', sm: '40px' }, // Add padding around the section
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        flexDirection: 'column', // Stack vertically
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginTop: '20px', // Ensure some space above for this section to be distinct
      }}
    >
      {/* Centered Heading */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          color: 'black', // Set the text color to black
          marginBottom: 2,
        }}
      >
        <span style={{ textDecoration: 'underline', fontWeight:'bold', textDecorationColor: 'purple', textDecorationThickness: '2px' }}>
          Our Expertise
        </span>
      </Typography>

      {/* Container for left and right grids */}
      <Box
        sx={{
          display: 'flex',
          width: '100%', // Full width on mobile
          justifyContent: 'space-between',
          gap: '20px',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile, horizontally on larger screens
          marginX: 'auto', // Center the container
        }}
      >
        {/* Left Grid - Our Vision Card */}
        <Card
          sx={{
            backgroundColor: 'black',
            color: 'white',
            flex: 1,
            padding: { xs: '20px', sm: '40px' }, // Reduce padding on mobile
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: { xs: '20px', sm: '0' }, // Add margin between cards on mobile
            boxSizing: 'border-box',
            width: '100%', // Ensure it takes the full width of the container
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Our Vision
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '16px' }}>
              To foster innovation and excellence in education, research, and societal impact.
            </Typography>
          </CardContent>
        </Card>

        {/* Right Grid - Our Mission Card */}
        <Card
          sx={{
            backgroundColor: 'black',
            color: 'white',
            flex: 1,
            padding: { xs: '20px', sm: '40px' }, // Reduce padding on mobile
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%', // Ensure it takes the full width of the container
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Our Mission
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '16px' }}>
              To provide transformative learning experiences, promote research, and serve the community.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default VisionMission;
