import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import { useTheme } from '@mui/material/styles';
import log from '../images/logoo.jpg'; // Assuming this is your logo path

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'About Us', link: 'https://www.getmycollege.com/about-us' },
    { text: 'Diploma Colleges', link: 'https://www.getmycollege.com' },
    { text: 'Contact Us', link: 'https://www.getmycollege.com/contact-us' },
  ];

  useEffect(() => {
    // Your popup logic can go here
  }, []);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'purple' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={log}
              alt="Logo"
              style={{
                height: '50px',
                width: '50px',
                borderRadius: '50%', // Make logo circular
                marginRight: '10px',
              }}
            />
          </Box>

          {/* Navigation or Hamburger Menu */}
          {isMobile ? (
            <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  sx={{ color: 'white', textTransform: 'none', fontSize: '1rem' }}
                  component="a"
                  href={item.link}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250, display: 'flex', flexDirection: 'column' }}>
          {/* Drawer Header */}
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '10px 16px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                fontWeight: 'bold',
                color: 'purple',
              }}
            >
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Drawer Menu Items */}
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component="a" href={item.link}>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
