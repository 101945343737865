import React from 'react'
import Home from './Backpage/Home'
const App = () => {
  return (
    <div>
<Home/>
    </div>
  )
}

export default App