import React from 'react';
import { Box, Typography } from '@mui/material';

const AnnouncementsMarquee = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        padding: '10px',
        border: '1px solid #ccc',
        position: 'relative',
        display: 'flex', // Flexbox for aligning text
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          animation: 'marquee 15s linear infinite', // Adjust duration as needed
        }}
      >
        <strong style={{ marginRight: '10px' }}>{'Announcements>>>'}</strong>
        Welcome to Diploma College Website ! Your future starts here. Stay tuned for important information. Check back regularly for the latest news!
      </Typography>
      <style>{`
        @keyframes marquee {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
      `}</style>
    </Box>
  );
};

const App = () => {
  return (
    <div>
      <AnnouncementsMarquee />
      {/* Other components can go here */}
    </div>
  );
};

export default App;
