import React from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Box, useMediaQuery, Grid } from '@mui/material';

const App = () => {
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect mobile screens

  const handleExploreMore = () => {
    window.location.href = 'https://www.getmycollege.com/colleges?state=karnataka';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1200px',
          padding: '15px 16px',
          margin: '0 auto',
        }}
      >
        {/* Heading */}
        <Box sx={{ width: '100%', textAlign: 'center', marginBottom: 3 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: 'black',
              textDecoration: 'underline',
              textDecorationColor: 'purple',
              textUnderlineOffset: 5,
            }}
          >
            Diploma Colleges
          </Typography>
        </Box>

        {/* Cards Section */}
        <Grid
          container
          spacing={3}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Center-align for all screen sizes
          }}
        >
          {/* Card Component Template */}
          {[
            {
              title: 'Engineering Diploma Colleges',
              description: 'Practical engineering skills, industry-oriented training, shorter courses.',
              image:
                'https://education.sakshi.com/sites/default/files/images/2024/07/15/engineering-colleges-1721021582.jpg',
            },
            {
              title: 'Paramedical Diploma Colleges',
              description: 'Healthcare training, patient care, practical skills, diagnostics.',
              image: 'https://www.georgetelegraph.com/img/g10.png',
            },
            {
              title: 'Pharmacy Diploma Colleges',
              description: 'Medication training, pharmacy practice, drug dispensing, healthcare.',
              image: 'https://www.srinivasacollegeofpharmacy.in/assets/images/cat-3.jpg',
            },
            {
              title: 'Design and Arts Diploma Colleges',
              description: 'Creative skills, design techniques, artistic training, innovation.',
              image:
                'https://aaftonline.com/blog/wp-content/uploads/2023/01/Animation-Courses-After-10th-scaled-1-1900x880.jpg',
            },
          ].map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center', // Properly center individual cards
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  maxWidth: isMobile ? '300px' : '210px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  ml: { xs: -4, md: -2 },
                  borderRadius: 2,
                  boxShadow: 3,
                  backdropFilter: 'blur(10px)',
                  transition: 'transform 0.3s ease',
                  position: 'relative',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  alt={card.title}
                  height="120"
                  image={card.image}
                  sx={{ borderRadius: 1, objectFit: 'cover' }}
                />
                <CardContent sx={{ textAlign: 'center', paddingBottom: isMobile ? 4 : 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '0.9rem',
                      marginBottom: 1,
                      fontWeight: 'bold',
                      color: 'black',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', marginBottom: 2 }}>
                    {card.description}
                  </Typography>
                </CardContent>
                <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                  <Button
                    href="https://www.getmycollege.com/"
                    target="_blank"
                    variant="contained"
                    sx={{
                      backgroundColor: 'purple',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                      fontSize: '12px',
                    }}
                  >
                    View More
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Explore More Button */}
        <Box sx={{ marginTop: 4, width: '100%', textAlign: 'center' }}>
          <Button
            onClick={handleExploreMore}
            variant="contained"
            sx={{
              backgroundColor: 'purple',
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              padding: '10px 30px',
              borderRadius: '20px',
              marginLeft:{xs:-5,md:0}
            }}
          >
            Explore More
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default App;
