import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material';

const EnquiryForm = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    state: '',
    city: '',
    degree: '',
    email: '',
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues); // You can send this data to an API or handle it as required
  };

  // List of states and cities (example data)
  const states = ['Karnataka', 'Maharashtra', 'Tamil Nadu', 'Andhra Pradesh'];
  const cities = ['Bangalore', 'Mumbai', 'Chennai', 'Hyderabad'];

  // List of degrees
  const degrees = [
    'Engineering Diploma Colleges',
    'Pharmacy Diploma Colleges',
    'Paramedical Diploma Colleges',
    'Design and Arts Diploma Colleges',
    'Other Vocational Colleges',
  ];

  return (
    <Grid container spacing={3}>
      {/* Left Grid - Enquiry Form */}
      <Grid item xs={12} sm={6} marginLeft={2}>
        <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: '8px',paddingLeft:2 }}>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 2,
              textAlign: 'center', // Center the text
              color: 'black', // Set text color to black
              textDecoration: 'underline', // Underline the text
              textDecorationColor: 'purple', // Set underline color to purple
            }}
          >
            Enquiry Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* First Name and Last Name */}
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                />
              </Grid>

              {/* State Dropdown */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    label="State"
                    name="state"
                    value={formValues.state}
                    onChange={handleInputChange}
                  >
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>{state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* City Dropdown */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>City</InputLabel>
                  <Select
                    label="City"
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>{city}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Degree Dropdown */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Degree</InputLabel>
                  <Select
                    label="Degree"
                    name="degree"
                    value={formValues.degree}
                    onChange={handleInputChange}
                  >
                    {degrees.map((degree) => (
                      <MenuItem key={degree} value={degree}>{degree}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Email Input */}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </Grid>

              {/* Apply Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: 'purple',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>

      {/* Right Grid - Image */}
      <Grid item xs={12} sm={5}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
          <img
            src="https://www.shighitechnologies.com/assets/images/enquiry.jpg"
            alt="Diploma Colleges"
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnquiryForm;
